/*======================
    404 page
=======================*/

.NotFoundPage {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3000;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.NotFoundPage .page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: "Arvo", serif;
    width: 100%;
}

.NotFoundPage .page_404 img {
    width: 100%;
}

.NotFoundPage .four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}

.NotFoundPage .four_zero_four_bg h1 {
    font-size: 80px;
}

.NotFoundPage .four_zero_four_bg h3 {
    font-size: 80px;
}

.NotFoundPage .link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
    cursor: pointer;
}

.NotFoundPage .contant_box_404 {
    margin-top: -50px;
}
