@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */

/* arabic font */
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

/* english font  */
@import url("https://fonts.googleapis.com/css2?family=Metrophobic&display=swap");
/* main setting */

* {
	font-family: var(--arabic-font-family); /* Default to Arabic font */
	scroll-behavior: smooth;
}
/* root */

:root {
	--main-color: #315ca7;
	--second-color: #faa726;

	--arabic-font-family: "Tajawal", sans-serif;
	--english-font-family: "Metrophobic", sans-serif;
}
/* ===================================== */
.main-page {
	overflow-x: hidden;
}

.default-btn {
	border: none;
	position: relative;
	display: inline-block;
	text-align: center;
	overflow: hidden;
	z-index: 1;
	color: #fff;
	background-color: var(--second-color);
	transition: 0.5s;
	border-radius: 5px;

	font-weight: 500;
	font-size: 18px;
}
.default-btn-ltr {
	padding: 14px 50px 14px 25px;
}
.default-btn-rtl {
	padding: 14px 25px 14px 50px;
}

.default-btn span {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-radius: 50%;
	background-color: var(--main-color);
	transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
	z-index: -1;
	border-radius: 5px;
}
.default-btn-rtl span {
	right: 0;

	transform: translate(50%, -50%);
	-webkit-transform: translate(50%, -50%);
	-moz-transform: translate(50%, -50%);
	-ms-transform: translate(50%, -50%);
	-o-transform: translate(50%, -50%);
}
.default-btn-ltr span {
	left: 0;
	transform: translate(0%, -50%);
	-webkit-transform: translate(0%, -50%);
	-moz-transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
	-o-transform: translate(0%, -50%);
}

.default-btn svg {
	position: absolute;

	top: 50%;
	transform: translateY(-50%);
	font-weight: 600;
	font-size: 18px;
}

.default-btn svg.ltr {
	right: 25px;
}
.default-btn svg.rtl {
	left: 25px;
}

.default-btn:hover {
	color: #fff;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	transform: translateY(-5px);
	-webkit-transform: translateY(-5px);
	-moz-transform: translateY(-5px);
	-ms-transform: translateY(-5px);
	-o-transform: translateY(-5px);
}
.default-btn:hover span {
	width: 225%;
	height: 562.5px;
}

/* white btn  */
.default-btn.white-btn span {
	background-color: #fff;
}
.default-btn.white-btn:hover,
.default-btn.white-btn:hover svg {
	color: var(--main-color);
}

/*  main btn */
.default-btn.main-color-btn {
	background-color: var(--main-color);
}

.default-btn.main-color-btn span {
	background-color: var(--second-color);
}
/* =========================================================== */
.webkit-line-clamp-title {
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	width: 100%;
}
.webkit-line-clamp-description {
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	width: 100%;
}

/* transitions */
.custom-transition {
	transition: 0.6s;
	-webkit-transition: 0.6s;
	-moz-transition: 0.6s;
	-ms-transition: 0.6s;
	-o-transition: 0.6s;
}

.custom-transition-animations {
	transition: 1.2s;
	-webkit-transition: 1.2s;
	-moz-transition: 1.2s;
	-ms-transition: 1.2s;
	-o-transition: 1.2s;
}
/* ====================================================== */

.custom-shadow {
	box-shadow: 0 5px 30px 0 rgb(31 34 120/8%);
	border: 1px solid rgb(31 34 120/8%);
}

/* ======================================================== */
/* animations */
.rotate-animate {
	animation: rotateme 40s linear infinite;
	-webkit-animation: rotateme 40s linear infinite;
}

@keyframes rotateme {
	0% {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(1turn);
	}
}

/* contact_us_info */
.contact_us_info {
	position: relative;

	background-image: url(./assets/img/contacus.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left;
	height: 100%;
	z-index: 1;
}

.contact_us_info::after {
	position: absolute;
	content: "";
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-color: #315ca7;
	opacity: 0.9;
	border-radius: 16px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	-ms-border-radius: 16px;
	-o-border-radius: 16px;
}

.ql-direction-rtl img {
	width: 40%;
	margin: 0 auto;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

@media (max-width: 768px) {
	.ql-direction-rtl img {
		width: 95%;
		margin: 0 auto;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
	}
}
